@import url("https://fonts.googleapis.com/css2?family=Stick+No+Bills&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap");

/*
=============== 
Variables
===============
*/

:root {
  --font-family-cursive: "Mukta", sans-serif;
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-primary-1: #f2a444;
  --clr-primary-2: #0f3240;
  --clr-primary-3: #050a26;
  --clr-primary-4: #f25774;
  --clr-primary-5: #eadec7;
  --clr-black: #222;
  --transition: all 0.75s linear;
  --spacing: 3px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Stick No Bills", sans-serif;
  background: var(--clr-primary-5);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

html {
  scroll-behavior: smooth;
  overflow-anchor: none;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 0.875rem;
}

p {
  color: var(--clr-grey-3);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--clr-primary-4);
  border-radius: 10px;
}
